body,
html {
    margin: 0 auto;
}

body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

ul.react-multi-carousel-dot-list {
    position: relative;
}
